import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Navigator from "./components/Navigator";
import FDD_App from "./components/Fdd_workflow/Fdd_App";
import Trigger_App from "./components/TriggerExecution/Trigger_App";
import Download_App from "./components/DownloadPage/Download_App";
import { AppProvider } from "./components/Fdd_workflow/AppContext";
import { ReactAuthWrapper } from "@carrier-sce-sharedpackages/reactauthwrapper";
import appConfig from "./config";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#006db9",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 5,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 18,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [activeComponent, setActiveComponent] = useState(
    process.env.REACT_APP_ENV === 'prod' ? 'FDD LOGS' : 'FDD TRIGGER'
  );

  const handleNavigation = (componentName) => {
    setActiveComponent(componentName);
  };

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [appAccess, setAppAccess] = useState(false);
  const callback = (permissions) => {
    console.log("permissions->", permissions);
    if (permissions.appAccess) {
      console.log("permissions->", permissions.appAccess);
      setAppAccess(true);
    }
  };

  console.log("Navigate component", activeComponent);
  return (
    <ReactAuthWrapper appConfig={appConfig.api} getPermissions={callback}>
      {appAccess && (
        <ThemeProvider theme={theme}>
          <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <CssBaseline />

            {/* Side NavBar */}
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
              {isSmUp ? null : (
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              )}

              <Navigator
                onNavigate={handleNavigation}
                PaperProps={{ style: { width: drawerWidth } }}
                sx={{ display: { sm: "block", xs: "none" } }}
              />
            </Box>

            {activeComponent === "FDD TRIGGER" && (
              <AppProvider>
                <Trigger_App></Trigger_App>
              </AppProvider>
            )}

            {activeComponent === "FDD LOGS" && (
              <AppProvider>
                <FDD_App></FDD_App>
              </AppProvider>
            )}

            {activeComponent === "DOWNLOAD RESULTS" && (
              <AppProvider>
                <Download_App></Download_App>
              </AppProvider>
            )}
          </Box>
        </ThemeProvider>
      )}
    </ReactAuthWrapper>
  );
}
