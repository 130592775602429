import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import { useAppContext } from './Fdd_workflow/AppContext';
import { API } from './MyApi';
import {logout} from '@carrier-sce-sharedpackages/auth-module';
import appConfig from "../config";

export default function Header({ name }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [user, setUser] = React.useState("User");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    get_chiller_list();
  }, []);

  const get_chiller_list = async () => {
    const param = {};
    try {
      const userdata = await API.getData("/get_username", param);
      console.log("userdata", userdata);

      if (userdata && userdata.username) {
        setUser(userdata.username);
      }
    } catch (error) {
      console.error("Failed to userdata", error);
    }
  };


  const handleLogout = () => {
    localStorage.clear();
    logout( appConfig.api, true );
}

  const { CustomTooltip } = useAppContext();
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography
                color="inherit"
                variant="h4"
                component="h1"
                sx={{
                  fontFamily:"monospace !important",
                  flex: 1,
                  margin: '2rem',
                  // Use `!important` by directly injecting CSS styles via `&`
                  '&': {
                    margin: '2rem !important'
                  }
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar sx={{ width: 56, height: 56, fontSize: "1.6rem", marginRight: theme.spacing(2), bgcolor: "#ce8600" }}>{user[0]}</Avatar>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: '1.3rem !important',
                  color: 'common.white',
                  fontWeight: 'bold !important',
                  marginRight: theme.spacing(2)
                }}
              >
                {user}
              </Typography>
            </Grid>

            <Grid item>
              <CustomTooltip title="Logout">
                <IconButton
                  sx={{
                    color: 'common.white',
                    marginRight: theme.spacing(3),
                  }}
                  onClick={handleLogout} // Define your logout function
                >
                  <LogoutIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>


          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
